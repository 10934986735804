import React from "react";
import '../css/errorPage.css'

export default function ErrorPage() {
    return (
        <div className="errorContainer">
            <p>Error: Invalid URL</p>
        </div>
    );
}


